import Vue from 'vue'
import VueRouter from 'vue-router'
import $ from 'jquery'
import beforeEach from './../src/plugins/auth'

import Home from "../src/Views/Home";
import Course from "../src/Views/Course";
import Category from "../src/Views/Category";
import MyCourses from "../src/Views/MyCourses";
import MyCourse from "../src/Views/MyCourse";
import Lesson from "../src/Views/Lesson";
import Material from "../src/Views/Material";
import Company from "../src/Views/Company";
import English from "../src/Views/English";
import EnglishCards from "../src/Views/EnglishCards";
import EnglishCardsList from "../src/Views/EnglishCardsList";
import Landing from "../src/Views/Landing.vue";
import Landing2 from "../src/Views/Landing2.vue";
import Auth from "@/Views/Auth.vue";

const routes = [
	{path: '/my/:course/lesson/:lesson/material/:material', name: 'my-course-lesson-material', component: Material},
	{path: '/my/:course/lesson/:lesson', name: 'my-course-lesson', component: Lesson},
	{path: '/my/:id', name: 'my-course-lessons', component: MyCourse},
	{path: '/my', name: 'my-courses', component: MyCourses},

	{path: '/english', name: 'english', component: English},
	{path: '/english/cards', name: 'english-cards', component: EnglishCards},
	{path: '/english/favorite', name: 'english-my-cards', component: EnglishCardsList},
	{path: '/english/cards/:category', name: 'english-card', component: EnglishCardsList},
	
	{path: '/category/:id?', name: 'category', component: Category},
	{path: '/course/:id', name: 'course', component: Course},

	{path: '/company/:id', name: 'company', component: Company},

    {path: '/landing_edu2', name: 'landing2', component: Landing2},
	{path: '/landing', name: 'landing', component: Landing},
    
	{path: '/auth', name: 'auth', component: Auth},
	
	{path: '/', name: 'home', component: Home},
];

Vue.use(VueRouter);

const router = new VueRouter({
	routes,
	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		window.historyInfo.from = from;
		window.historyInfo.to = to;
		
		window.events.$emit('page-changed');

		$('html, body').animate({
			scrollTop: 0
		}, 400);
	}
});

router.beforeEach(beforeEach);

export default router;
